import React from "react"
import { graphql } from "gatsby"
import { BlogLayout } from "../components/layouts"
import SEO from "../components/seo"
import "./blog.scss"

const BlogTemplate = props => {
  const post = props.data.markdownRemark

  return (
    <BlogLayout>
      <SEO
        title={post.frontmatter.titletag}
        ogDescription={post.frontmatter.description}
      />
      <article className="blog-post">
        {post.frontmatter.previewImage && (
          <img src={`/images/articles/${post.frontmatter.previewImage}`} />
        )}
        <h1>{post.frontmatter.title}</h1>
        <section
          className="markdown"
          dangerouslySetInnerHTML={{ __html: post.html }}
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        ></div>
      </article>
    </BlogLayout>
  )
}

export default BlogTemplate

export const pageQuery = graphql`
  query BlogBySlug($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      frontmatter {
        title
        titletag
        previewImage
        date
        author
      }
    }
  }
`
